import requestBety2 from '@/plugins/requestBety2';
const key = '87774122b5854528b498111104e04288';
export const getCarTrack = (data) => {
  // 入参处理
  return requestBety2.post(`/transTimeManage?key=${key}&vnos=${data.vnos}`).then((res) => {
    //  回参处理
    return res;
  });
};
export const getCarRouterPath = (data) => {
  return requestBety2.post(`/routerPath?key=${key}&vclN=${data.vclN}&vco=${data.vco}&qryBtm=${data.qryBtm}&qryEtm=${data.qryEtm}`).then((res) => {
    //  回参处理
    return res;
  });
};
