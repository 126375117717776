
import axios from 'axios';
import { Message } from 'element-ui';
// 错误提示msg
let showInfo = false;
const errorMessage = (msg) => {
  if (!showInfo) {
    Message.error(msg);
    showInfo = true;
  }
  setTimeout(() => {
    showInfo = false;
  }, 500);
};
// 判断当前接口状态
const handleCode = (code, msg) => {
  switch (+code) {
    case 1002:
      errorMessage(msg || `参数不正确`);
      break;
    case 1006:
      errorMessage(msg || `无结果`);
      break;
    case 9001:
      errorMessage(msg || `系统异常`);
      break;
    case 1090:
      errorMessage(msg || `限制请求`);
      break;
    default:
      errorMessage(msg || `服务器内部发生错误`);
      break;
  }
};
const requestTimeout = 30000;

const instance = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: 'https://openapi.shipdt.com/transport/lstrans',
  // baseURL: 'https://api.shipdt.com/transport/lstrans',
  timeout: requestTimeout,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    const { data } = response;
    const code = +data.status;
    const message = data.message;

    if (code !== 1001) {
      handleCode(code, message);
      return Promise.reject('error');
    } else {
      return data?.result;
    }
  },
  (error) => {
    const { message, response } = error;
    const newErrorMessage = message.includes('Request failed with status code')
      ? `${response.status} 服务器接口异常` : message;
    handleCode(response.status, newErrorMessage);
    return Promise.reject(error);
  },
);

export default instance;
