<template>
  <div class="right-panel-wrap">
    <div class="car-order-detail-wrap">
      <div class="card-wrap">
        <div class="car-info-detail-wrap">
          <div class="car-info flex-b">
            <div id="carNumber" class="top-car-che">{{ carDetail.vno || "--" }}</div>
            <div v-clipboard:copy="carDetail.vno" v-clipboard:success="onCopy" class="right main-c-cursor">
              <span class="fz_14">复制车牌号</span>
              <img src="@/assets/images/monitor/copy.svg">
            </div>
            <!-- <div class="top-car-type" :style="{ color: returnColor(), background: returnBackground() }">
              <div class="status-text">{{ $CONSTANT.carOrderStateMap.O[+carDetail.state] || "--" }}</div>
            </div> -->
          </div>
          <div class="people-company-info">
            <div class="compamy-wrap">
              <i class="iconfont icon-building-3-line" />
              <span>{{ carDetail.customerName || "-" }}</span>
            </div>
          </div>
        </div>
        <div class="box-card-wrap">
          <div>
            <div>
              <span>当前车速 </span>
              <span class="value">{{ carDetail.spd||'-' }}km/h</span>
            </div>
            <div>
              <span>剩余行驶 </span>
              <span class="value">{{ carDetail.residueDistance||'-' }}km</span>
            </div>
            <div>
              <span>预计时长 </span>
              <span class="value">{{ carDetail.residueDriverTime||'-' }}</span>
            </div>
          </div>
          <div>
            <div>
              <span>当前位置：</span>
              <span>{{ carDetail.adr||'-' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-wrap park-wrap">
        <div class="title-wrap">
          <img src="@/assets/images/monitor/red-warning.svg">
          <span>告警</span>
        </div>
        <!-- {{ item.parkBte|parseTime('MM-DD HH:mm:ss') }} -->
        <div class="park-list">
          <div v-for="(item, i) in parkArray" :key="i" class="item">
            <div class="time-wrap">
              {{ item.parkBte|parseTime('HH:mm:ss') }}
            </div>
            <div class="right-wrap">
              <img src="@/assets/images/monitor/car-2.svg">
              <div class="park-info">
                <span class="info-type">停车 {{ item.parkMins }} 分钟</span>
                <span class="info-addres">告警位置：{{ item.address }}</span>
              </div>
            </div>
          </div>
          <div v-if="!parkArray.length" class="no-data">暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime } from '@/utils';
// import Big from 'big.js';
export default {
  filters: {
    parseTime,
  },
  props: {
    carDetail: {
      type: Object,
      default() {
        return {};
      },
    },
    parkArray: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
    };
  },

  computed: {
  },

  // watch: {
  //   parkArray(val) {
  //     console.log(val, 'parkArray----val');
  //   },
  // },

  created() {
  },

  mounted() {},

  methods: {
    onCopy() {
      this.$baseMessage('复制成功!', 'success');
    },
    returnColor() {
      // if (this.dialogMarkerData.status === '运输中') {
      //   return '#10CE66';
      // } else if (this.dialogMarkerData.status === '空闲') {
      //   return '#1A6AFF';
      // } else if (this.dialogMarkerData.status === '离线') {
      //   return '#58595C';
      // } else {
      //   return '#04122b';
      // }
      return '#04122b';
    },
    returnBackground() {
      // if (this.dialogMarkerData.status === '运输中') {
      //   return 'rgba(16, 206, 102, 0.1)';
      // } else if (this.dialogMarkerData.status === '空闲') {
      //   return 'rgba(26, 106, 255, 0.1)';
      // } else if (this.dialogMarkerData.status === '离线') {
      //   return 'rgba(88, 89, 92, 0.1)';
      // } else {
      //   return 'rgba(4,18,43, 0.1)';
      // }
      return 'rgba(4,18,43, 0.1)';
    },
  },
};

</script>
<style lang='scss' scoped>
.right-panel-wrap{
  font-size: 14px;
  color: rgba(21,24,29,0.64);
  .car-order-detail-wrap {
    position: absolute;
    top: 10px;
    right: 12px;
    .card-wrap {
      width: 520px;
      min-height: 57px;
      background: #ffffff;
      border-radius: 4px;
      padding: 17px 20px 20px 20px;
      box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
      margin-bottom: 10px;
      .box-card-wrap {
        margin-top: 16px;
        >div{
          display: flex;
          align-items: center;
          >div{
            width: 33.3%;
            .value{
              color: #15181D;
              font-weight: bold;
            }
          }
        }
        &>:last-child{
          margin-top: 10px;
          >div{
            width: 100%;
          }
        }
      }
    }
    .car-info-detail-wrap {
      .car-info {
        display: flex;
        .top-car-che {
          width:94px;
          line-height: 28px;
          text-align: center;
          border-radius: 4px;
          background: url("~@/assets/images/monitor/carBg2.png") no-repeat;
          background-size: 100% 100%;
          font-size: 16px;
          color: #04122b;
          margin-right: 10px;
        }
        .top-car-type {
          line-height: 28px;
          padding: 0 12px;
          background: rgba(16, 206, 102, 0.1);
          border-radius: 4px;
          color: #10ce66;
          font-size: 14px;
          font-weight: 400;
          position: relative;
          margin-right: 30px;
          .status-text {
            // width: 42px;
            text-align: center;
          }
        }
      }
      .people-company-info {
        margin-top: 10px;
        color: #58595c;
        .compamy-wrap {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          i {
            font-size: 20px;
            margin-right: 6px;
          }
          span {
            font-weight: bold;
          }
        }
      }
    }
    .park-wrap{
      .title-wrap{
        display: flex;
        align-items: center;
        >span{
          margin-left: 5px;
          color: #EF5063;
        }
      }
      .park-list{
        height: calc(100vh - 400px);
        max-height: 400px;
        overflow-y: scroll;
        .item {
          padding: 5px 0;
          display: flex;
          .time-wrap{
            font-size: 14px;
            text-align: center;
          }
          .right-wrap {
            margin-left: 5px;
            display: flex;
            flex: 1;
            position: relative;
            // min-height: 50px;
            &::before {
              content: "";
              position: absolute;
              display: block;
              width: 1px;
              border-left: 1px solid #C0DEFF;
              top: 20px;
              margin-left: -33px;
              height: calc(100% - 10px);
              background: transparent;
            }
            img{
              width: 33px;
              height: 33px;
            }
            .park-info{
              display: flex;
              flex-direction: column;
              .info-type{
                color: #15181D;
              }
            }
          }
        }
        .no-data{
          text-align: center;
          padding: 15px;
        }
        >:last-child{
          .right-wrap {
            position: relative;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
