<template>
  <div class="map-container">
    <fullscreen :fullscreen.sync="fullscreen" class="fullMap-wrap">
      <div class="fullMap">
        <!-- :style="{ height: !fullscreen ? '610px' : '100vh' }" -->
        <div id="mapBig" ref="mapBig" class="map" />
        <!-- <img :class="['full-img', fullscreen ? 'all-unfull' : 'all-full']" :src="require(`@/assets/images/monitor/${fullscreen ? 'allunfull' : 'allfull'}.svg`)" @click="screen"> -->
        <div class="left-card">
          <div class="flex">
            <el-button class="car-button" icon="el-icon-search" type="primary" @click="searchMark" />
            <el-input v-model="carNumber" placeholder="请输入车牌号" clearable @keyup.enter.native="searchMark" />
          </div>
          <div>
            <el-date-picker
              v-model="createTime"
              type="datetimerange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="pickerOptions"
              @change="timeChange"
            />
          </div>
        </div>
        <RightPanel
          v-if="showDetail"
          class="right-card"
          :car-detail="carDetailInfo"
          :park-array="parkArray"
        />
      </div>
    </fullscreen>
  </div>
</template>

<script>
// getCarRouterPath
import { getCarTrack, getCarRouterPath } from '@/api/transport/map/map';
import RightPanel from './carLog/rightPanel';
import AMapLoaderLass from '@amap/amap-jsapi-loader';
import dayjs from 'dayjs';
export default {
  components: { RightPanel },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          // 计算四个月前的日期
          const fourMonthsAgo = new Date();
          fourMonthsAgo.setMonth(fourMonthsAgo.getMonth() - 4);

          // 阻止选择早于四个月前的日期
          const isBeforeFourMonths = time.getTime() < fourMonthsAgo.getTime();
          // 阻止选择当前时间之后的日期
          const isAfterNow = time.getTime() > new Date().getTime();

          return isBeforeFourMonths || isAfterNow;
        },
      },
      timer: null, // 定时器状态
      marker: null,
      fullscreen: false,
      carNumber: '', // 车牌号
      createTime: [],
      beginTime: 0, // 开始时间
      endTime: 0, // 结束时间
      carDetailInfo: {}, // 车辆基础信息
      lnglatList: [], // 轨迹不加装卸货点经纬度集合
      parkArray: [], // 停车数据（告警数据）
      showDetail: false, // 是否展示右侧卡片
      carMap: new Map(),
      carMarkerMap: new Map(),
    };
  },
  watch: {
    showDetail(val) {
      if (val) {
        this.startTimer();
      } else {
        this.endTimer();
      }
    },
  },
  created() {
    this.toggleCollapse();
  },
  destroyed() {
    this.$store.commit('common/setCollapse', false);
    this.endTimer();
  },
  mounted() {
    this.initMap();
  },
  methods: {
    toggleCollapse() {
      setTimeout(() => {
        this.$store.commit('common/setCollapse', true);
      });
    },
    initMap() {
      AMapLoaderLass.load({
        key: '2ce614857c44af9c3cc5f2691e537ed9', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Driving', 'AMap.MoveAnimation', 'AMap.MouseTool', 'AMap.LabelMarker', 'AMap.Geocoder'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: '1.1', // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
        Loca: {
          // 是否加载 Loca， 缺省不加载
          version: '1.3.2', // Loca 版本，缺省 1.3.2
        },
      })
        .then((AMap) => {
          this.mapInstance = new AMap.Map('mapBig');
          this.AMap = AMap;
          this.geocoder = new AMap.Geocoder();
          this.autoSearchHandle();
          // window.myMap = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 自动搜索
    autoSearchHandle() {
      const { carNumber } = this.$route.query;
      if (carNumber) {
        this.carNumber = carNumber;
        this.beginTime = dayjs(new Date().getTime() - 72 * 60 * 60 * 1000).format('YYYY-MM-DD HH:mm:ss');
        this.endTime = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
        this.createTime = [this.beginTime, this.endTime];
        this.searchMark();
      }
    },
    startTimer() {
      if (this.timer) clearInterval(this.timer); // 清空上一个定时器
      this.timer = setInterval(() => {
        this.getCarLocation(true);
        this.getCarTrackAndPark(true);
      }, 30000); // 每60秒发送一次请求
    },
    endTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    // 搜索车+时间
    searchMark() {
      this.carDetailInfo = {};
      this.parkArray = [];
      this.showDetail = false;
      if (!this.carNumber) {
        this.$baseMessage('请输入车牌号!', 'error');
        return;
      }
      if (!this.createTime.length) {
        this.$baseMessage('请选择开始结束时间!', 'error');
        return;
      }
      this.mapInstance.clearMap(); // 清空画布
      this.getCarLocation();
      this.getCarTrackAndPark();
    },
    // 选择时间
    timeChange(e) {
      this.beginTime = e?.[0] || null;
      this.endTime = e?.[1] || null;
      if (e && e.length === 2) {
        const [startDate, endDate] = e;
        // 检查时间区间是否为72小时内
        const timeDiff = new Date(endDate).getTime() - new Date(startDate).getTime();
        if (timeDiff > 72 * 60 * 60 * 1000) {
          this.$baseMessage('请选择72小时内的时间区间!', 'error');
          this.createTime = [];
        }
      }
    },
    // 获取车辆当前位置
    getCarLocation(noFirst) {
      const params = {
        vnos: this.carNumber + '_2',
      };
      getCarTrack(params).then((res) => {
        this.carDetailInfo = res?.firstVcl || {};
        if (noFirst) {
          const currentMark = this.carMarkerMap.get(this.carNumber)[0];
          const currentText = this.carMarkerMap.get(this.carNumber)[1];
          const currentText1 = this.carMarkerMap.get(this.carNumber)[2];
          const markHtml3 = `<div class="${this.carClassName(this.carDetailInfo.spd)}" ><div class="speed-item1" >${this.carDetailInfo.spd}</div><div class="speed-item2" >km/h</div></div>`;
          currentText1.setText(markHtml3);
          this.carMoving(currentMark, currentText, currentText1, `${this.carDetailInfo.lon / 600000.0},${this.carDetailInfo.lat / 600000.0}`, +this.carDetailInfo.spd);
        } else {
          const carMarker = this.drawCarMark(this.carDetailInfo.vno, `${this.carDetailInfo.lon / 600000.0},${this.carDetailInfo.lat / 600000.0}`, this.carDetailInfo.drc, this.carDetailInfo.spd);
          this.carMarkerMap.set(this.carDetailInfo.vno, carMarker);
        // 118.919264,32.150595
        }
        this.showDetail = true;
      });
    },
    // 获取车辆行驶轨迹和告警信息
    getCarTrackAndPark(noFirst) {
      const params = {
        vclN: this.carNumber, // 车牌号
        vco: 2, // 车牌颜色 2黄色
        qryBtm: this.beginTime, // 开始时间
        qryEtm: this.endTime, // 结束时间
      };
      getCarRouterPath(params).then(res => {
        if (res.parkArray.length) {
          this.addAddressToObjects(res.parkArray).then(newParkArray => {
            this.parkArray = newParkArray || [];
          }).catch(error => console.error(error));
        } else {
          this.parkArray = [];
        }
        this.lnglatList = res?.trackArray || [];
        // 划轨迹
        this.drawLine(noFirst);
      });
      // .catch(error => {
      //   console.log(error, 'error');
      // });
    },
    // 获取地址详情
    async addAddressToObjects(array) {
      const promises = array.map(async(obj) => {
        const address = await this.getAddress([obj.parkLon / 600000.0, obj.parkLat / 600000.0]);
        return { ...obj, address };
      });
      const updatedArray = await Promise.all(promises);
      return updatedArray;
    },
    // 新增车辆标记点
    drawCarMark(carNo, carLngLat, angle, speed) {
      const markHtml2 = `<div class="bg-car2" >${carNo}</div>`;
      const markHtml3 = `<div class="${this.carClassName(speed)}" ><div class="speed-item1" >${speed}</div><div class="speed-item2" >km/h</div></div>`;
      const img = require('@/assets/images/monitor/carPng2.png');
      // 创建一个 Icon
      const startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(24, 24),
        // 图标的取图地址
        image: img,
        // 图标所用图片大小
        imageSize: new AMap.Size(24, 24),
        // 图标取图偏移量
      });
      const marker = new AMap.Marker({
        position: new AMap.LngLat(...carLngLat.split(',')),
        icon: startIcon, // 添加 Icon 图标 URL
        title: `${carNo}`,
        offset: new AMap.Pixel(-9, -9),
        angle,
        autoRotation: true,
      });
      // marker.on('click', (e) => {
      //   this.MapClick(carNo, carLngLat, angle, speed, e);
      // });
      // 将 markers 添加到地图
      this.mapInstance.add(marker);
      const text = new AMap.Text({
        text: markHtml2,
        position: new AMap.LngLat(...carLngLat.split(',')),
        anchor: 'bottom-left',
        offset: new AMap.Pixel(-18, -20),
      });
      // text.on('click', (e) => {
      //   this.MapClick(carNo, carLngLat, angle, speed, e);
      // });
      this.mapInstance.add(text);
      const text1 = new AMap.Text({
        text: markHtml3,
        position: new AMap.LngLat(...carLngLat.split(',')),
        anchor: 'bottom-left',
        offset: new AMap.Pixel(-60, -9),
      });
      // text1.on('click', (e) => {
      //   this.MapClick(carNo, carLngLat, angle, speed, e);
      // });
      this.mapInstance.add(text1);
      // 设置label标签
      // marker.setLabel({
      //   content: markHtml, //设置文本标注内容
      //   direction: "top", //设置文本标注方位
      // });
      this.mapInstance.setFitView();
      return [marker, text, text1];
    },
    // 清空标记点
    clearMarkers() {
      this.markers.forEach((marker) => {
        marker.setMap(null); // 将标记点从地图上移除
      });
      this.markers = []; // 清空数组
    },
    // 车辆点位移动
    carMoving(mark, text, text1, carLngLat, speed) {
      const lnglat = new AMap.LngLat(...carLngLat.split(','));
      mark.moveTo(lnglat, speed);
      text.moveTo(lnglat, speed);
      text1.moveTo(lnglat, speed);
    },
    carClassName(speed) {
      if (+speed > 80) {
        return 'bg-speed-red';
      } else if (+speed <= 80 && +speed > 0) {
        return 'bg-speed-green';
      } else if (+speed === 0) {
        return 'bg-speed-gray';
      }
    },
    // 地图上车辆点击 需要车单轨迹弹窗打卡
    // MapClick(carNo, carLngLat, angle, speed, e) {
    //   this.showDetail = true;
    // },
    drawLine(noFirst) {
      if (!this.AMap) return;
      // 轨迹绘画
      const lnglatList = this.lnglatList || [];
      const PolylineList = lnglatList.map((item) => {
        return [item.lon / 600000.0, item.lat / 600000.0];
      });
      new this.AMap.Polyline({
        map: this.mapInstance,
        path: PolylineList,
        showDir: true,
        strokeColor: '#428BFF', // 线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, // 线宽
        // strokeStyle: "solid"  //线样式
      });
      // const line = this.loadCarLine(PolylineList); // 获取已行驶公
      if (!noFirst) {
        this.mapInstance.setFitView();
      }
    },
    // 根据经纬度获取当前车辆位置
    getAddress(lnglat) {
      return new Promise((resolve, reject) => {
        this.geocoder.getAddress(lnglat, (status, result) => {
          let address;
          if (status === 'complete' && result.regeocode) {
            address = result.regeocode.formattedAddress;
            resolve(address);
          } else {
            console.log('根据经纬度查询地址失败');
            address = '未知道路';
            reject(address);
          }
        });
      });
    },
  },
};
</script>
<style lang="scss">
.amap-marker-label {
  border: none;
  background: none;
}
.amap-overlay-text-container {
  border: none;
  background: none;
}
// .bg-car {
//   height: 26px;
//   width: 84px;
//   text-align: center;
//   font-size: 14px;
//   color: #04122b;
//   line-height: 26px;
//   // background: #0d1b36;
//   // background: #4385ff;
//   background: url("~@/assets/images/monitor/carBg2.png") no-repeat;
//   background-size: 100% 100%;
//   border-radius: 2px;
// }
.bg-car2 {
  height: 26px;
  width: 84px;
  text-align: center;
  font-size: 14px;
  color: #04122b;
  line-height: 26px;
  // background: #0d1b36;
  // background: #4385ff;
  background: url("~@/assets/images/monitor/carBg2.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 2px;
}
.bg-speed-red {
  width: 48px;
  height: 48px;
  border: 2px solid #d52137;
  border-radius: 50% 50%;
  background: #ef5063;
  color: #ffffff;
}
.bg-speed-green {
  width: 48px;
  height: 48px;
  border: 2px solid #10ce66;
  border-radius: 50% 50%;
  background: #ffffff;
  color: #16c375;
}
.bg-speed-gray {
  width: 48px;
  height: 48px;
  border: 2px solid #58595c;
  border-radius: 50% 50%;
  background: #ffffff;
  color: #58595c;
}
.speed-item1 {
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  padding-top: 6px;
}
.speed-item2 {
  text-align: center;
  font-size: 12px;
  line-height: 12px;
}
</style>
<style lang="scss" scoped>
.map-container{
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.fullMap-wrap {
  // position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.fullMap {
  position: relative;
  width: 100%;
  height: 100%;
  // height: 610px;
  // height:100vh;
  padding: 0;
  margin: 0;
}
.left-card{
  position: absolute;
  top: 10px;
  left: 12px;
  display: flex;
  width:580px;
  ::v-deep.el-input-group__append,
  .el-input-group__prepend {
    background-color: #409eff;
    color: #fff;
  }
  ::v-deep {
    .el-button,
    .el-input__inner {
      border-radius: 0;
    }
  }
}

.map {
  width: 100%;
  height: 100%;
  max-height: 100%;
}
.map-full {
  color: #4385ff;
  font-size: 30px;
  position: absolute;
  top: 15px;
  right: 50px;
  z-index: 1;
  cursor: pointer;
}

.full-img {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.all-full {
  top: -44px;
  right: 40px;
  &:hover {
    opacity: 0.9;
  }
}
</style>
